export default `
    <p><strong>1. Acceptance of Privacy Policy</strong></p>
    <p>Welcome to Violux and our Privacy Policy (&ldquo;<strong>Privacy Policy</strong>&rdquo;). This Privacy Policy is important and affects your legal rights, so please read carefully.</p>
    <p>Violux, Inc. and its affiliates and subsidiaries (collectively, &ldquo;<strong>Violux</strong>&rdquo; &ldquo;we&rdquo; &ldquo;us,&rdquo; or &ldquo;our&rdquo;) are as passionate about privacy as we are about giving you tools to help you protect what you care about most. We support industry standards for data protection designed to keep your personal information private and in your control.</p>
    <p>This Privacy Policy describes how we collect, use, disclose, transfer, and otherwise process your Personal Information. It also tells you about your rights with respect to your Personal Information, and how you can reach us to update it or get answers to questions you may have about our privacy practices. While we think this document is both transparent and easy to understand, do not hesitate to contact us if you have questions (how to do that is outlined below).</p>
    <p>By accessing or using Violux products and services (collectively, the &ldquo;<strong>Products</strong>&rdquo;) and Violux.com and various related websites (collectively, the &ldquo;<strong>Site</strong>&rdquo;), you agree to be bound by this Privacy Policy and all of the terms incorporated herein by reference.This Privacy Policy describes the types of personal information <strong>Violux</strong> collects through the Site and how we collect, use, and share that information.</p>
    <p>The purpose of this Privacy Policy is to provide to users and potential users of our Site and Products with information about the nature, scope, and purpose of the personal information we collect, use and process and to advise data subjects of their rights.Our processing of personal information, such as your name, address, e-mail address, or telephone number, shall be undertaken consistent with the requirements of applicable privacy laws.Whether we serve as the data controller or processor, we have implemented numerous technical and organizational measures to ensure the protection of personal information.However, Internet-based data transmissions may in principle have security gaps, so please understand that absolute protection is not assured.</p>
    <p>By using our Products and/or the Site, you accept and expressly agree to our practices surrounding the collection, use, and sharing of personal information provided by you in the manner described in this Privacy Policy.If you do not agree with the terms of this Privacy Policy, you cannot, and we do not authorize you to, access, browse, or use our Products and/or the Site.</p>
    <p><strong>2.&nbsp;Information We Collect</strong></p>
    <p>Some elements of our Products and the Site require us to learn more about you so that we can best meet your needs.</p>
    <p><strong>2.1 Personal information We Collect Directly From You</strong></p>
    <p>We receive personal information directly from you when you voluntarily provide us with such personal information, including, without limitation, the following:</p>
    <p>(1)&nbsp;Registration Information: We may collect contact information (such as your first name, last name, shipping address, billing address, e-mail addresses (for sign-up, notifications, newsletters and related purposes) and phone numbers) and demographic data&nbsp;(such as your gender, your date of birth and your zip code) when you register your Products, create an account on our Site, or sign up to be on our mailing list.</p>
    <p>(2)&nbsp;Customer Support: We may collect personal information through your communications with our customer support team or other communications that you may send us.</p>
    <p>(3) Payment Information: We and our payment providers may collect payment information (such as credit card details and billing information) to process your orders.</p>
    <p>(3)&nbsp;Other Information: We may collect other identifying information that you voluntarily choose to provide to us, including without limitation unique identifiers such as passwords, and personal information in messages that you send to us.</p>
    <p>We may also collect additional information, which may be personal information, as otherwise described to you at the point of collection or pursuant to your consent. You may still access and use some portions of the Products or Site if you choose not to provide us with any personal information, but features of the Products or Site that require your personal information will not be accessible to you.</p>
    <p><strong>2.2 Information From Third Party Sources</strong></p>
    <p>Some third parties, such as our business partners and service providers, provide us with personal information about you, such as the following:</p>
    <ul>
    <li><strong>Account information for third party services</strong>:If you interact with a third party service when using our Site, such as if you use a third party service to log-in to our Site (e.g., Facebook or Google), or if you share content from our Site through a third party social media service, the third party service will send us certain information about you if the third party service and your account settings allow such sharing.The information we receive will depend on the policies and your account settings with the third party service.You understand the information transmitted to us is covered by this Privacy Policy; for example, the basic information we receive from Facebook about you.</li>
    </ul>
    <p><strong>2.3 Information we automatically collect when you use our Site</strong></p>
    <p>In order to access and use certain areas or features of the Site, you consent to our collection and use of certain information about your use of the Site through the use of tracking technologies or by other passive means.Your consent to our access and use of this &ldquo;passively collected&rdquo; information includes, but is not limited to, the domain name of the website that allowed you to navigate to the Site, search engines used, the internet protocol (IP) address used, the length of time spent on the Site, the pages you looked at on the Site, other webpages you visited before and after visiting the Site, the type of internet browser you have, the frequency of your visits to the Site, and other relevant statistics, including the following:</p>
    <ul>
    <li><strong>Traffic Data</strong>: We also may automatically collect certain data when you use the Site, such as:(1)&nbsp;IP address; (2)&nbsp;domain server; (3)&nbsp;type of device(s)&nbsp;used to access the Site; (4)&nbsp;web browser(s)&nbsp;used to access the Site; (5)&nbsp;referring webpage or other source through which you accessed the Site; (6)&nbsp;geolocation information; and (7)&nbsp;other statistics and information associated with the interaction between your browser or device and the Site (collectively, &ldquo;<strong>Traffic Data</strong>&rdquo;). Depending on applicable law, some Traffic Data may be personal information.</li>
    </ul>
    <p><strong>3.&nbsp;How We Collect Information</strong></p>
    <p>We collect information (including personal information and Traffic Data) when you use and interact with the Site, and in some cases from third party sources.Such means of collection may include:</p>
    <ul>
    <li>When you use the Site&rsquo;s interactive tools and services;</li>
    <li>When you voluntarily provide information in free-form text boxes through the Site or through responses to surveys, questionnaires and the like;</li>
    <li>If you use a location-enabled browser, we may receive information about your location and mobile device, as applicable;</li>
    <li>Through cookies, web beacons, analytics services and other tracking technology (collectively, &ldquo;<strong>Tracking Tools&rdquo;</strong>), as described below; and</li>
    <li>When you use the &ldquo;Contact Us&rdquo; function on the Site, send us an email or otherwise contact us.</li>
    </ul>
    <p><strong>4.&nbsp;Tracking Tools, Behavioral Advertising, and Opt Out Options</strong></p>
    <p><strong>4.1.&nbsp;Tracking Tools</strong></p>
    <p>We may use tools outlined below in order to provide our Site to, advertise to, and to better understand users.</p>
    <ul>
    <li><strong>Cookies:</strong> &ldquo;Cookies&rdquo; are small computer files transferred to your computing device that contain information such as user ID, user preferences, lists of pages visited and activities conducted while using the Site.We use cookies to improve or tailor the Site, customize advertisements by tracking navigation habits, measuring performance, and storing authentication status so re-entering credentials is not required, customize user experiences with the Site, and for analytics and fraud prevention.For more information on cookies, including how to control your cookie settings and preferences, visit http://www.allaboutcookies.org.</li>
    </ul>
    <ul>
    <ul>
    <li>Some cookies are placed by a third party on your device and provide information to us and third parties about your browsing habits&nbsp;(such as your visits to our Site, the pages you have visited, and the links and advertisements you have clicked).These cookies can be used to determine whether certain third party services are being used, to identify your interests, and to serve advertisements relevant to you.&nbsp;We do not control third party cookies.</li>
    </ul>
    </ul>
    <ul>
    <li><strong>Web Beacons:</strong>&ldquo;Web Beacons&rdquo;&nbsp;(a.k.a.&nbsp;clear GIFs or pixel tags) are tiny graphic image files embedded in a web page or email that may be used to collect information about the use of our Site, the websites of selected advertisers and the emails, special promotions or newsletters that we send.The information collected by Web Beacons allows us to analyze how many people are using the Site, using selected publishers&rsquo; websites or opening emails, and for what purpose, and also allows us to enhance our interest-based advertising (discussed further below).</li>
    </ul>
    <ul>
    <li><strong>Web Service Analytics:</strong>We may use third-party analytics services in connection with the Site, including, for example, to register mouse clicks, mouse movements, scrolling activity and text typed into the Site.We use the information collected from these services to help make the Site easier to use and as otherwise set forth in Section 5 (How We Use Your Information).These analytics services generally do not collect personal information unless you voluntarily provide it and generally do not track your browsing habits across web services that do not use their services.</li>
    </ul>
    <ul>
    <li><strong>Mobile Device Identifiers:</strong>As with other Tracking Tools, mobile device identifiers help Violux learn more about our users&rsquo; demographics and internet behaviors in order to personalize and improve the Site.Mobile device identifiers are data stored on mobile devices that may track mobile device and data and activities occurring on and through it, as well as the applications installed on it.Mobile device identifiers enable collection of personal information&nbsp;(such as media access control, address and location) and Traffic Data.</li>
    </ul>
    <p><strong>4.2.&nbsp;Behavioral Advertising</strong></p>
    <p>We may use a type of advertising commonly known as interest-based or online behavioral advertising.This means that some of our partners use Tracking Tools, such as cookies, pixel tags, and web beacons, to collect information about a user&rsquo;s online activities to display Violux ads to the user based on the user&rsquo;s interests&nbsp;(&ldquo;<strong>Behavioral Advertising</strong>&rdquo;).Such partners may include third-party service providers, advertisers, advertising networks or platforms, traffic measurement service providers, marketing analytics service providers, and other third party service providers (collectively, &ldquo;<strong>Advertising Service Providers</strong>&rdquo;).Other Tracking Tools used by our partners may collect information when you use the Site, such as IP address, mobile device ID, operating system, browser, web page interactions, geographic location and demographic information, such as gender and age range.These Tracking Tools help Violux learn more about our users&rsquo; demographics and internet behaviors.</p>
    <p><strong>4.3.&nbsp;Options for Opting out of Cookies and Mobile Device Identifiers</strong></p>
    <p>Some web browsers allow you to reject cookies or to alert you when a Cookie is placed on your computer, tablet or mobile device.You may be able to reject mobile device identifiers by activating the appropriate setting on your mobile device.Although you are not required to accept Violux&rsquo;s cookies or mobile device identifiers, if you block or reject them, you may not have access to all features available through the Site.</p>
    <ul>
    <li>You may opt out of receiving certain cookies and certain trackers by visiting the Network Advertising Initiative&nbsp;(NAI) opt out page or the Digital Advertising Alliance&nbsp;(DAA) opt out page, or by installing the DAA&rsquo;s AppChoice app (for&nbsp;iOS; for&nbsp;Android)&nbsp;on your mobile computing device.When you use these opt-out features, an &ldquo;opt-out&rdquo; Cookie will be placed on your computer, tablet or mobile computing device indicating that you do not want to receive interest-based advertising from NAI or DAA member companies.If you delete cookies on your computer, tablet or mobile computing device, you may need to opt out again.For information about how to opt out of interest-based advertising on mobile devices, please visit http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device.You will need to opt out of each browser and device for which you desire to apply these opt-out features.</li>
    </ul>
    <ul>
    <li>Even after opting out of Behavioral Advertising, you may still see Violux advertisements that are not interest-based&nbsp;(i.e., not targeted toward you).&nbsp;Also, opting out does not mean that Violux is no longer using Tracking Tools &mdash; Violux still may collect information about your use of the Site even after you have opted out of Behavioral Advertising and may still serve advertisements to you via the Site based on information it collects via the Site.</li>
    </ul>
    <p>This Privacy Policy does not cover the use of cookies and other Tracking Tools by any third parties, and we aren&rsquo;t responsible for their privacy policies and practices.Please be aware that some cookies placed by third parties can continue to track your activities online even after you have left our Site.</p>
    <p><strong>4.4.&nbsp;How Violux Responds to Browser &ldquo;Do Not Track&rdquo;&nbsp;(DNT)&nbsp;Signals</strong></p>
    <p>Some web browsers&nbsp;(including Safari, Internet Explorer, Firefox and Chrome)&nbsp;incorporate a &ldquo;Do Not Track&rdquo; (DNT) or similar feature that signals to web services that a visitor does not want to have his/her online activity and behavior tracked.If a web service operator elects to respond to a particular DNT signal, the web service operator may refrain from collecting certain personal information about the browser&rsquo;s user.Not all browsers offer a DNT option and there is currently no industry consensus as to what constitutes a DNT signal.For these reasons, many web service operators, including Violux, do not proactively respond to DNT signals.For more information about DNT signals, visit http://allaboutdnt.com.</p>
    <p><strong>5.&nbsp;How We Use Your Information</strong></p>
    <p>We may use information (including information that has been de-identified and/or aggregated) to better understand who uses our Products and the Site and how we can deliver a better user experience.</p>
    <p>We use information, including personal information, to provide the Products and the Site and to help improve the Product and the Site, to develop new services, and to advertise (for example, to display Violux ads on other web services).Specifically, such use may include:</p>
    <ul>
    <li>Providing you with Violux Products, the Site, and information you request;</li>
    <li>Responding to correspondence that we receive from you;</li>
    <li>Contacting you when necessary or requested, including to remind you of an upcoming appointment;</li>
    <li>Providing, maintaining, administering or expanding the Site, performing business analyses, or for other internal purposes to support, improve or enhance our business, the Site, and other Products that we offer;</li>
    <li>Customizing or tailoring your experience of Products and the Site, which may include sending customized messages or information;</li>
    <li>Notifying you about certain resources or services we think you may be interested in learning more about;</li>
    <li>Sending you information about Violux or our Products or the Site;</li>
    <li>Sending emails and other communications that display content that we think will interest you and according to your preferences;</li>
    <li>Combining information received from third parties with information that we have from or about you and using the combined information for any of the purposes described in this Privacy Policy;</li>
    <li>Showing you advertisements, including interest-based or online behavioral advertising;</li>
    <li>Using statistical information that we collect in any way permitted by law, including from third parties in connection with their commercial and marketing efforts;</li>
    <li>Fulfilling our legally required obligations, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities;</li>
    <li>Resolving disputes;</li>
    <li>Protecting against or deterring fraudulent, illegal, or harmful actions; and</li>
    <li>Enforcing our Privacy Policy and other agreements.</li>
    </ul>
    <p><strong>6.&nbsp;How We Share Your Information</strong></p>
    <p>In certain circumstances, and in order to provide the Products and/or the Site, we may share certain information that we collect from you, as described in this section:</p>
    <ul>
    <li><strong>Products and Site</strong>: We will use your Personal Information to deliver the Products, provide and maintain the Site and to interact with you directly. We will also use your Personal Information to ensure our Products and the Site are working as intended and to make improvements to our Products and the Site.</li>
    <li><strong>Internal data analyses</strong>: We use personal information for internal analytics to understand how our Products and Site are used.</li>
    <li><strong>Marketing</strong>: We may send you marketing communications about Products, services, offers, programs and promotions (including contests, sweepstakes and any other marketing activities) either directly from us or from our partners. These might be our own offers or Products, or offers for third-party products we think you might find interesting, but we do not share information with third parties for their independent marketing or promotional purposes.</li>
    <li><strong>Advertising</strong>: We may share your personal information with our partners to customize or display our advertising.</li>
    <li><strong>Service Providers</strong>: We may share your personal information and/or Traffic Data with our partners who perform operational services&nbsp;(such as hosting, billing, fulfillment, data storage, security, insurance verification, web service analytics, or ad serving)&nbsp;and/or who make certain services, features or functionality available to our users.</li>
    <li><strong>Cross Device Matching</strong>: To determine if users have interacted with content across multiple devices and to match such devices, we work with partners who analyze device activity data and/or rely on your information&nbsp;(including demographic, geographic and interest-based data).&nbsp;To supplement this analysis, we also provide de-identified data to these partners.Based on this data, we may then display targeted advertisements across devices that we believe are associated or use this data to further analyze usage of the Site across devices.</li>
    <li><strong>Business Transfers</strong>: We may transfer your personal information to another company in connection with a proposed merger, sale, acquisition or other change of ownership or control by or of Violux (whether in whole or in part).Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.</li>
    <li><strong>Protection of Violux and Others</strong>:We also may need to disclose your personal information or any other information we collect about you if we determine in good faith that such disclosure is needed to:(1)&nbsp;comply with or fulfill our obligations under applicable law, regulation, court order or other legal process; (2)&nbsp;protect the rights, property or safety of you, Violux or another party; (3)&nbsp;enforce this Privacy Policy or other agreements with you; or (4)&nbsp;respond to claims that any posting or other content violates third-party rights.</li>
    <li><strong>Other Information</strong>: We may disclose information that is neither personal information and/or has been de-identified and/or aggregated&nbsp;at our discretion.</li>
    </ul>
    <p>Violux is a global business. We may transfer your personal information to recipients in countries other than your country, including the United States, where we are headquartered. These countries may not have the same data protection laws as the country in which you initially provided the information. When we transfer your personal information to other countries, we will protect that information as described in this Privacy Policy or as disclosed to you at the time of data collection. By using our Products and/or the Site or providing any personal information, where applicable law permits, you agree to the transfers, processing, and storage of your personal information outside of your country of residence.</p>
    <p><strong>7.&nbsp;Storage and Security of Information</strong></p>
    <p>The security of your personal information is important to us.&nbsp;We endeavor to follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and in storage.</p>
    <p>We store and process your information on our servers in the United States.We maintain industry standard backup and archival systems.Although we make good faith efforts to store personal information in a secure operating environment that is not open to the public, we do not and cannot guarantee the security of your personal information.If at any time during or after our relationship we believe that the security of your personal information may have been compromised, we may seek to notify you of that development.If a notification is appropriate, we will endeavor to notify you as promptly as possible under the circumstances. If we have your e-mail address, we may notify you by e-mail to the most recent e-mail address you have provided us.If you receive a notice from us, you can print it to retain a copy of it.To receive these notices, you must check your e-mail account using your computer or mobile device and email application software.<strong>You consent to our use of e-mail as a means of such notification.If you prefer for us to use the U.S.&nbsp;Postal Service to notify you in this situation, please e-mail us at hello@violux.com</strong>Please include your address when you submit your request.You can make this election any time, and it will apply to notifications we make after a reasonable time thereafter for us to process your request.You may also use this e-mail address to request a print copy, at no charge, of an electronic notice we have sent to you regarding a compromise of your personal information.</p>
    <p><strong>8.&nbsp;Your Choices and Rights</strong></p>
    <p>You can always opt not to disclose information to us, but keep in mind some information may be needed to register with us or to take advantage of some of our features. To stop receiving our marketing and promotional emails, follow the instructions in any marketing email you get from us. Even if you opt-out of getting marketing and promotional emails, we will still be sure to send you important transactional messages.&nbsp;</p>
    <p>If you are a California resident, you have certain rights over your personal information held by Violux under the California Consumer Privacy Act (the &ldquo;<strong>CCPA</strong>&rdquo;). California residents are entitled once a year, free of charge, to request and obtain certain information regarding our disclosure, if any, of certain categories of personal information to third parties for their direct marketing purposes in the preceding calendar year. Please proceed to the following link for additional information about your rights and choices with respect to your personal information under the CCPA: www.violux.com/privacy-policy</p>
    <p>If you are a Nevada resident, you have the right to opt-out of the sale of your personal information collected by us.</p>
    <p>You can request access to, or deletion of, your personal information that we collect and maintain through our Services by contacting us at <strong>hello@violux.com</strong> with &ldquo;Request for Privacy Information&rdquo; on the subject line and in the body of your message.We will provide the requested information to you at your e-mail address in response. Whichever way you elect to contact us, we may ask that you confirm and verify your identity.</p>
    <p>If you are located in the European Economic Area (&ldquo;<strong>EEA</strong>&rdquo;), we process your personal information for the purposes listed herein based on the following legal grounds:</p>
    <ul>
    <li>You have consented to the use of your personal information. For example, we may seek to obtain your consent for our uses of cookies when you visit our website, or to send you marketing communications.</li>
    <li>We need your personal information to provide you with Products and Services requested by you, or to respond to your inquiries.</li>
    <li>We have a legal obligation to use or disclose your personal information, for example, if we are responding to a legal process or an enforceable governmental request.</li>
    <li>We (or a third party) have a legitimate interest in using your personal information. In particular, we have a legitimate interest in using your personal information for things like:</li>
    <ul>
    <li>Providing, maintaining, and improving our Services to meet the needs of our users</li>
    <li>Developing new products and features that are useful for our users</li>
    <li>Understanding how people use our Services to ensure and improve the performance of our Services</li>
    <li>Customizing our Services to provide you with a better user experience</li>
    <li>Delivering marketing information to inform existing customers about our Services</li>
    <li>Detecting, preventing, or otherwise addressing fraud, abuse, security, or technical issues with our Services</li>
    <li>Protecting against harm to the rights, property or safety of Violux, our users, or the public as required or permitted by law</li>
    <li>Performing research that improves our services for our users and benefits the public</li>
    <li>Fulfilling obligations to our partners like developers and rights holders; and</li>
    <li>Enforcing legal claims, including investigation of potential violations of applicable Site Terms of Use.</li>
    </ul>
    </ul>
    <p>We comply with applicable legal requirements providing adequate safeguards for the transfer of personal information to countries other than the country where you are located. In particular, we use contractual protections for the transfer of personal information to third parties, such as the European Commission&rsquo;s Standard Contractual Clauses. You may contact us as specified below to obtain a copy of such document.</p>
    <p>You have the right to request access and receive information about the personal information we maintain about you, to update and correct inaccuracies in your personal information, to restrict or object to the processing of your personal information, to have the information blocked or deleted, as appropriate, or to exercise your right to data portability to easily transfer your personal information to another company. Those rights may be limited in some circumstances by local law requirements. In addition to the above-mentioned rights, you also have the right to lodge a complaint with a competent supervisory authority subject to applicable law.</p>
    <p>In addition, if we rely on consent for the processing of your personal information, you have the right to withdraw it at any time and free of charge. When you do so, this will not affect the lawfulness of the processing before your consent withdrawal.</p>
    <p>Violux is the entity responsible for the processing of your personal information, and you may exercise your rights to your personal information by emailing us at: <strong>[Violux&rsquo;s email address].</strong></p>
    <p><strong>9.&nbsp;How Long We Retain Your Information</strong></p>
    <p>We retain personal information about you for as long as necessary to provide you the Products and the Site.In some cases, we retain personal information for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, prevent fraud, enforce this Privacy Policy, or as otherwise permitted or required by applicable law, rule or regulation.Afterwards, we retain some information in a depersonalized or aggregated form but not in a way that would identify you personally.</p>
    <p><strong>10.&nbsp;Information Provided on Behalf of Children and Others</strong></p>
    <p>The Site is not intended for use by children and children under the age of age of majority in their jurisdiction are prohibited from using the Site.Violux does not knowingly collect any information from children, nor are the Site directed to children.If you are under the age of majority in your jurisdiction, please do not attempt to register for the Site or send any personal information about yourself to us.</p>
    <p>By accessing, using and/or submitting information to or through the Site, you represent that you are not younger than the age of majority in your jurisdiction.If we learn that we have received any information directly from a child under the age of majority in the relevant jurisdiction without his/her parent&rsquo;s written consent, we will use that information only to respond directly to that child&nbsp;(or his/her/their parent or legal guardian) to inform the child that he/she cannot use the Site, and we will subsequently delete that information.If you believe that a child under the age of majority may have provided us personal information, please contact us at <strong>hello@violux.com</strong></p>
    <p>If you are under the age of majority in your place of residence, you may use the Site only with the consent of or under the supervision of your parent or legal guardian.If you are a parent or legal guardian of a minor child, you may, in compliance with this Privacy Policy, use the Site on behalf of such minor child.Any information that you provide us while using the Site on behalf of your minor child will be treated as personal information as otherwise provided herein.</p>
    <p>If you use the Site on behalf of another person, regardless of age, you agree that Violux may contact you for any communication made in connection with providing the Site or any legally required communications.You further agree to forward or share any such communication with any person for whom you are using the Site on behalf.</p>
    <p><strong>11.&nbsp;Other Web Services</strong></p>
    <p>The Site contains links to or embedded content from third party web services.A link to or embedded content from a non-Violux web service does not mean that we endorse that web service, the quality or accuracy of information presented on the non-Violux web service or the persons or entities associated with the non-Violux web service.If you decide to visit a third party web service, you are subject to the privacy policy of the third party web service as applicable and we are not responsible for the policies and practices of the third party web service.We encourage you to ask questions before you disclose your information to others.</p>
    <p>You may have arrived at the Site from, or began your use of the Site at, a third party web service, including a third party web service that links to Violux or embeds Violux content.The presence of such links or content on third party web services does not mean that we endorse that web service, the quality or accuracy of information presented on the non-Violux web service or the persons or entities associated with the non-Violux web service.You may be subject to the privacy policy of the third party web service as applicable and we are not responsible for the policies and practices of the third party web services.In addition, the policies and practices of third parties do not apply to your information, including personal information, obtained pursuant to this Privacy Policy.</p>
    <p><strong>12.&nbsp;Updates and Changes to Privacy Policy</strong></p>
    <p>The effective date of this Privacy Policy is set forth at the top of this webpage.We will notify you of any material change by posting notice on this webpage.Your continued use of Products and/or the Site after the effective date constitutes your acceptance of the amended Privacy Policy.We encourage you to periodically review this page for the latest information on our privacy practices.Any amended Privacy Policy supersedes all previous versions.IF YOU DO NOT AGREE TO FUTURE CHANGES TO THIS PRIVACY POLICY, YOU MUST STOP USING THE SERVICES AFTER THE EFFECTIVE DATE OF SUCH CHANGES.</p>
    <p><strong>13.&nbsp;Contact Us</strong></p>
    <p>If you have any comments, concerns or questions about this Privacy Policy, please contact us at <strong>Violux, Inc.</strong></p>
    <p><strong>5270 California Ave, #100</strong></p>
    <p><strong>Irvine, CA 92617</strong></p>
    <p><strong>Website: www.violux.com</strong></p>
    <p><strong>Email: hello@violux.com</strong></p>
    <p><strong>Violux Cookie Policy</strong></p>
    <p>This Cookie Policy describes how Violux, Inc. (&ldquo;Violux,&rdquo; &ldquo;us,&rdquo; &ldquo;we&rdquo; or &ldquo;our&rdquo;) and our third-party providers set cookies and similar technologies through violux.com (the &ldquo;<strong>Site</strong>&rdquo;). This Cookie Policy provides information about how we set and use cookies and similar technologies to store and manage user preferences, deliver targeted advertising, enable content, and gather analytic and usage data, and how you can control and manage them.</p>
    <p><strong>What is a cookie?</strong></p>
    <p>A cookie is a small text file that is placed on a computer or other device and is used to identify the user or device and to collect information. Cookies are typically assigned to one of four categories, depending on their function and intended purpose: absolutely necessary cookies, performance cookies, functional cookies, and cookies for marketing purposes.</p>
    <p>Types of cookies and why we use them</p>
    <ul>
    <li>Absolutely necessary cookies. These cookies are essential to enable you to move around a website and use its features. Without these cookies, services you have asked for, like adding items to an online shopping cart, cannot be provided.</li>
    <li>Performance cookies. These cookies collect information about how you use our Site. Information collected includes, for example, the Internet browsers and operating systems used, the domain name of the website previously visited, the number of visits, average duration of visit, and pages viewed. These cookies don&rsquo;t collect information that personally identifies you and only collect aggregated and anonymous information. Performance cookies are used to improve the user-friendliness of a website and enhance your experience.</li>
    <li>Functionality cookies. These cookies allow the Site to remember choices you make (such as your username or ID, language preference, or the area or region you are in) and provide enhanced, more personal features. These cookies can also be used to remember changes you have made to text size, fonts, and other customizable parts of web pages. They may also be used to provide services you have asked for, such as watching a video or commenting on a blog. The information these cookies collect may be anonymized, and they cannot track your browsing activity on other websites.</li>
    </ul>
    <p><strong>Managing cookies</strong></p>
    <p>You can manage cookies in your web browser (for example, Edge, Explorer, Chrome, Safari, etc.) settings, and you always have the choice to change these settings by accepting, rejecting, or deleting cookies. If you choose to change your settings, you may find that certain functions and features will not work as intended on the services. Cookie settings are slightly different depending on the browser used, so to manage cookies, you should refer to the program documentation and the relevant settings within your particular browser.</p>
    <p>We understand that you may want to know more about cookies. Here are some useful resources that provide detailed information about types of cookies, how they are used, and how you can manage your cookie preferences:</p>
    <ol>
    <li>Local shared objects/Flash cookies. Flash cookies, also known as local shared objects, are designed to support browser content supported by Adobe Flash. They are usually used to enable ads and video content on websites. Like other cookies, they will store information on your device, some of which will be specific to the Flash-enabled content. Flash cookies can only be deleted within Adobe Flash rather than via your browser. Please refer to Adobe Flash&rsquo;s help page for information on how to manage your privacy settings and deletion of Flash cookies.</li>
    </ol>
    <ol>
    <li>Web beacons. Our web pages may contain electronic images known as web beacons (also called single-pixel gifs and transparent graphic images) that we use to help deliver cookies on our sites, count users who have visited those sites, deliver services, and analyze the effectiveness of our promotional campaigns, for example. We may also include web beacons in our marketing email messages or newsletters to determine whether an email is opened or if links are clicked.</li>
    </ol>
    <ol>
    <li>Web server &amp; application logs. Our servers automatically collect certain information to help us administer and protect the services, analyze usage, and improve users&rsquo; experience. The information collected includes:</li>
    </ol>
    <ul>
    <li>IP address and browser type;</li>
    <li>Device information including Unique Device Identifier (UDID), MAC address, Identifier For Advertisers (IFA), and similar identifiers we or others may assign;</li>
    <li>Device operating system and other technical facts;</li>
    <li>The city, state/province, and country from which you access our site;</li>
    <li>Pages visited and content viewed, stored, and purchased;</li>
    <li>Information or text entered;</li>
    <li>Links and buttons clicked; and</li>
    <li>URLs visited before and after you use our services</li>
    </ul>
    <ol>
    <li>Interest-based advertising (IBA). IBA allows us to deliver targeted advertising to users of our services. IBA works by showing you advertisements that are based on the type of content you access or read. For example, as you browse our services, one of the cookies placed on your device will be an advertising cookie so we can better understand what sort of pages or content you are interested in. The information collected about your device enables us to group you with other devices that have shown similar interests. We can then display advertising to categories of users that is based on common interests. For more information about IBA, please visit: http://www.iab.net/public_policy/behavioral-advertisingprinciples.</li>
    </ol>
    <ol>
    <li>Opting out of IBA. If you want to opt out of receiving interest-based advertising, it does not mean that you will no longer receive advertising when you are using our services. It just means that we will not use information collected about you for IBA and that any advertising you see will not be customized and may not be relevant to you. You can exercise your online advertising choices at http://optout.aboutads.info or by clicking the AdChoices icon in an ad and following the instructions. You may also opt out of receiving interest-based ads from many sites through the Network Advertising Initiative&rsquo;s (NAI) Opt Out Tool (http://www.networkadvertising.org/choices) and in the EU at http://www.youronlinechoices.com/. Remember, if you delete cookies, use a different device, or change web browsers, you may need to opt out again.</li>
    </ol>
    <ol>
    <li>Advertising on mobile devices. Mobile devices have an identifier that gives companies the ability to serve targeted ads to a specific mobile device. In many cases, you can turn off mobile device ad tracking or you can reset the advertising identifier at any time within your mobile device privacy settings. Another tool you can use to control advertising on your mobile device is the AppChoices App: http://youradchoices.com/appchoices. You may also choose to turn off location tracking on your mobile device. If you turn off ad tracking or location tracking, we will no longer use information collected from your device&rsquo;s advertising identifier for the purposes of advertising. You may still see the same number of ads but they may be less relevant because they will not be based on your interests or location.</li>
    </ol>
    <ol>
    <li>Do not track. Some browsers transmit Do Not Track (DNT) signals to websites. Due to the lack of a common interpretation of DNT signals throughout the industry, we do not currently alter, change, or respond to DNT requests or signals from these browsers. We will continue to monitor industry activity in this area and reassess our DNT practices as necessary.</li>
    </ol>
    <ol>
    <li>Connecting via social networks. Some of our services may include social networking features, such as the Facebook &ldquo;Like&rdquo; button and widgets, &ldquo;Share&rdquo; buttons, and interactive mini-programs. Additionally, you may choose to use your own social networking logins from, for example, Facebook or LinkedIn, to log into some of our services. If you choose to connect using a social networking or similar service, we may receive and store authentication information from that service to enable you to log in and other information that you may choose to share when you connect with these services. These services may collect information such as the web pages you visited and IP addresses, and may set cookies to enable features to function properly. We are not responsible for the security or privacy of any information collected by these third parties. You should review the terms of use and privacy policies applicable to the third-party services you connect to, use, or access. If you do not want your personal information shared with your social media account provider or other users of the social media service, please do not connect your social media account with your account for the services and do not participate in social sharing on the services.</li>
    </ol>
    <ol>
    <li>Links and connections to third-party services. Our services may contain links to and may be used by you in conjunction with third-party apps, services, tools, and websites that are not affiliated with, controlled, or managed by us. Examples include Facebook, LinkedIn, Twitter and, third-party apps like voice software and readers. The privacy and other practices of these third parties will be governed by the parties&rsquo; own terms of use and privacy policies. We are not responsible for the security or privacy of any information collected by these third parties. You should review the terms of use and privacy policies applicable to these third-party services.</li>
    </ol>
    <ol>
    <li>Opting out of consent to use cookies. If we process cookies based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any point in time by contacting us at: hello@violux.com. Please note, that if you exercise this right, you may have to then provide your consent on a case-by-case basis for the use by us or disclosure of certain cookies, if such use or disclosure is necessary to enable you to utilize some or all of our services.</li>
    </ol>
    <p><strong>PRIVACY NOTICE FOR CALIFORNIA RESIDENTS</strong></p>
    <p><strong>Last Updated: September 14, 2020</strong></p>
    <p>This Privacy Notice for California Residents (&ldquo;<strong>Privacy Notice</strong>&rdquo;) supplements the information contained in the Privacy Policy for Violux, Inc. (&ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>&rdquo; or &ldquo;<strong>our</strong>&rdquo;) and applies solely to visitors, users, and others who visit the Violux website and various related websites, software, website applications, mobile device applications, and services (collectively, the &ldquo;<strong>Site</strong>&rdquo;) and applies solely to Site visitors, users, and others who reside in the State of California (&ldquo;<strong>consumers</strong>&rdquo; or &ldquo;<strong>you</strong>&rdquo;).We adopt this Privacy Notice to comply with the California Consumer Privacy Act of 2018 (&ldquo;CCPA&rdquo;) and other California privacy laws. Any terms defined in the CCPA have the same meaning when used in this Privacy Notice.</p>
    <p><strong>Information We Collect</strong></p>
    <p>Our Site collects information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device ("<strong>personal information</strong>").In particular, we have collected the following categories of personal information from its consumers within the last twelve (12) months:</p>
    <table cellspacing="0" cellpadding="0">
    <tbody>
    <tr>
    <td>
    <p><strong>Category</strong></p>
    </td>
    <td>
    <p><strong>Examples</strong></p>
    </td>
    <td>
    <p><strong>Collected</strong></p>
    </td>
    </tr>
    <tr>
    <td>
    <p>A. Identifiers.</p>
    </td>
    <td>
    <p>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver's license number, passport number, or other similar identifiers.</p>
    </td>
    <td>
    <p>YES</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code &sect; 1798.80(e)).</p>
    </td>
    <td>
    <p>A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver's license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information.</p>
    <p>Some personal information included in this category may overlap with other categories.</p>
    </td>
    <td>
    <p>YES</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>C. Protected classification characteristics under California or federal law.</p>
    </td>
    <td>
    <p>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</p>
    </td>
    <td>
    <p>NO</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>D. Commercial information.</p>
    </td>
    <td>
    <p>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</p>
    </td>
    <td>
    <p>YES</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>E. Biometric information.</p>
    </td>
    <td>
    <p>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</p>
    </td>
    <td>
    <p>NO</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>F. Internet or other similar network activity.</p>
    </td>
    <td>
    <p>Browsing history, search history, information on a consumer's interaction with a website, application, or advertisement.</p>
    </td>
    <td>
    <p>YES</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>G. Geolocation data.</p>
    </td>
    <td>
    <p>Physical location or movements.</p>
    </td>
    <td>
    <p>YES</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>H. Sensory data.</p>
    </td>
    <td>
    <p>Audio, electronic, visual, thermal, olfactory, or similar information.</p>
    </td>
    <td>
    <p>NO</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>I. Professional or employment-related information.</p>
    </td>
    <td>
    <p>Current or past job history or performance evaluations.</p>
    </td>
    <td>
    <p>NO</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</p>
    </td>
    <td>
    <p>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.</p>
    </td>
    <td>
    <p>NO</p>
    </td>
    </tr>
    <tr>
    <td>
    <p>K. Inferences drawn from other personal information.</p>
    </td>
    <td>
    <p>Profile reflecting a person's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</p>
    </td>
    <td>
    <p>YES</p>
    </td>
    </tr>
    </tbody>
    </table>
    <p>Personal information does not include:</p>
    <ul>
    <li>Publicly available information from government records.</li>
    <li>Deidentified or aggregated consumer information.</li>
    <li>Information excluded from the CCPA's scope, like:</li>
    <ul>
    <li>health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</li>
    <li>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.</li>
    </ul>
    </ul>
    <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
    <ul>
    <li>Directly from you. For example, from forms you complete or products and services you purchase.</li>
    <li>Indirectly from you. For example, from observing your actions on our Site.</li>
    </ul>
    <p><strong>Use of Personal Information</strong></p>
    <p>We may use or disclose the personal information we collect for one or more of the following purposes:</p>
    <ul>
    <li>Providing you with Violux Products, the Site, and information you request;</li>
    <li>Responding to correspondence that we receive from you;</li>
    <li>Contacting you when necessary or requested, including to remind you of an upcoming appointment;</li>
    <li>Providing, maintaining, administering or expanding the Site, performing business analyses, or for other internal purposes to support, improve or enhance our business, the Site, and other Products that we offer;</li>
    <li>Customizing or tailoring your experience of Products and the Site, which may include sending customized messages or information;</li>
    <li>Notifying you about certain resources or services we think you may be interested in learning more about;</li>
    <li>Sending you information about Violux or our Products or the Site;</li>
    <li>Sending emails and other communications that display content that we think will interest you and according to your preferences;</li>
    <li>Combining information received from third parties with information that we have from or about you and using the combined information for any of the purposes described in this Privacy Policy;</li>
    <li>Showing you advertisements, including interest-based or online behavioral advertising;</li>
    <li>Using statistical information that we collect in any way permitted by law, including from third parties in connection with their commercial and marketing efforts;</li>
    <li>Fulfilling our legally required obligations, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities;</li>
    <li>Resolving disputes;</li>
    <li>Protecting against or deterring fraudulent, illegal, or harmful actions; and</li>
    <li>Enforcing our Privacy Policy and other agreements.</li>
    </ul>
    <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
    <p><strong>Sharing Personal Information</strong></p>
    <p>For information generally on how we share your personal information and to which entities, please refer to our Privacy Policy at www.violux.com/privacy-policy</p>
    <p><strong>Disclosure of Personal Information</strong></p>
    <p>In the preceding twelve (12) months, we have disclosed the following categories of personal information for a business purpose:</p>
    <p>Category A: Identifiers.</p>
    <p>Category B: California Customer Records personal information categories.</p>
    <p>Category D: Commercial information.</p>
    <p>We disclose your personal information for business purposes to third party technology service providers who provide hosting, database, network, and related services to support Site operations.</p>
    <p><strong>Sales of Personal Information</strong></p>
    <p>In the preceding twelve (12) months, we have not sold personal information.</p>
    <p><strong>Your Rights and Choices</strong></p>
    <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information.This section describes your CCPA rights and explains how to exercise those rights.</p>
    <p><strong>Access to Specific Information and Data Portability Rights</strong></p>
    <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months.Once we receive and confirm your verifiable consumer request, we will disclose to you:</p>
    <ul>
    <li>The categories of personal information we collected about you.</li>
    <li>The categories of sources for the personal information we collected about you.</li>
    <li>Our business or commercial purpose for collecting or selling that personal information.</li>
    <li>The categories of third parties with whom we share that personal information.</li>
    <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
    <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</li>
    <ul>
    <li>sales, identifying the personal information categories that each category of recipient purchased; and</li>
    <li>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
    </ul>
    </ul>
    <p><strong>Deletion Request Rights</strong></p>
    <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions.Once we receive and confirm your verifiable consumer request, we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
    <p>We may deny your deletion request if retaining the information is necessary for us or our service providers to:</p>
    <ul>
    <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you;</li>
    <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities;</li>
    <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
    <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law;</li>
    <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code &sect; 1546&nbsp;<em>seq.</em>);</li>
    <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent;</li>
    <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us;</li>
    <li>Comply with a legal obligation; and</li>
    <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
    </ul>
    <p><strong>Exercising Access, Data Portability, and Deletion Rights</strong></p>
    <p>To exercise the access, data portability, opt out, and deletion rights described above, please submit a verifiable consumer request to us in one of the following ways:</p>
    <p><strong>Violux, Inc.</strong></p>
    <p><strong>5270 California Ave, #100</strong></p>
    <p><strong>Irvine, CA 92617</strong></p>
    <p><strong>Website: www.violux.com</strong></p>
    <p><strong>Email: hello@violux.com</strong></p>
    <p>Only you or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.</p>
    <p>You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
    <ul>
    <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative; and</li>
    <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</li>
    </ul>
    <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor's identity or authority to make the request.</p>
    <p><strong>Response Timing and Format</strong></p>
    <p>We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option. Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request's receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
    <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>
    <p><strong>Non-Discrimination</strong></p>
    <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
    <ul>
    <li>Deny you goods or services.</li>
    <li>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</li>
    <li>Provide you a different level or quality of goods or services.</li>
    <li>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</li>
    </ul>
    <p><strong>Changes to Our Privacy Notice</strong></p>
    <ul>
    <li>The effective date of this Privacy Notice is set forth at the top of the Site.We will notify you of any material change by posting notice on the Site.Your continued use of the Site after the effective date constitutes your acceptance of the amended Privacy Notice.We encourage you to periodically review this page for the latest information on our privacy practices.Any amended Privacy Policy supersedes all previous versions.IF YOU DO NOT AGREE TO FUTURE CHANGES TO THIS PRIVACY NOTICE, YOU MUST STOP USING THE SITE AFTER THE EFFECTIVE DATE OF SUCH CHANGES.</li>
    </ul>
    <p><strong>Contact Information</strong></p>
    <ul>
    <li>If you have any questions or comments about this Privacy Notice, the ways in which we collect and uses your information described int his policy your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us:</li>
    </ul>
    <p><strong>Violux, Inc.</strong></p>
    <p><strong>5270 California Ave, #100</strong></p>
    <p><strong>Irvine, CA 92617</strong></p>
    <p><strong>Website: www.violux.com</strong></p>
    <p><strong>Email: hello@violux.com</strong></p>
`;
